/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Row, Spinner } from 'reactstrap';
import Select from 'react-select';
import Button from '../../Components/Button/Button';
import { getCustomers, attachCustomerToTechnical } from '../../Service/Api';

class AttachCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            loading: true,
            selectedCustomers: [],
            customStyles: {
                container: () => ({
                    width: '90%',
                    marginLeft: '5%',
                }),
                menu: () => ({
                    marginLeft: 0,
                    width: '100%',
                    position: 'relative',
                    border: '1px solid #dee2e6 !important',
                    borderTop: '0px',
                    borderRadius: '0 0 5px 5px',
                }),
            },
        };
    }

    componentDidMount() {
        const { data } = this.props;
        console.log(data);
        this.setState({ loading: true });
        getCustomers(data.id).then((response) => {
            this.setState({
                customers: this.convertForm(response.data),
                selectedCustomers: this.convertForm(data.attached_customers),
            }, () => {
                this.setState({
                    loading: false,
                });
            });
        }).catch((error) => {
            this.handleError(error);
        });
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        }

        this.setState({ loading: false });
    }

    convertForm = (form) => {
        const data = [];
        form.forEach((item) => {
            data.push({ value: item.id, label: item.name });
        });
        return data;
    }

    onChangeSelect = (data) => {
        this.setState({
            selectedCustomers: data,
        });
    }

    handleSubmit = () => {
        const ids = [];
        const { data, callback } = this.props;
        const { selectedCustomers } = this.state;
        if (selectedCustomers) {
            selectedCustomers.forEach((item) => {
                ids.push(item.value);
            });
        }

        attachCustomerToTechnical({ id: data.id, customers: ids })
            .then(() => {
                callback();
            }).catch((error) => {
                this.handleError(error);
            });
    }


    render() {
        const {
            selectedCustomers, customStyles, customers, loading,
        } = this.state;

        return (
            <>
                <Row>
                    {!loading && (
                        <Select
                            defaultValue={selectedCustomers}
                            styles={customStyles}
                            options={customers}
                            isMulti
                            onChange={this.onChangeSelect}
                        />
                    )}
                </Row>
                <div className="form-footer">
                    {loading && <div className="spinner"><Spinner /></div>}
                    <Button text="Asignar" onClick={this.handleSubmit} disabled={loading} />
                </div>
            </>
        );
    }
}


export default AttachCustomer;
