/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import ReactTable from 'react-table';
// import matchSorter from 'match-sorter';
import TopBar from '../../Components/TopBar/TopBar';
import { StateContext } from '../../State';
import DownloadCSVButton from '../../Components/DownloadCSVButton/DownloadCSVButton';
import ClientForm from './InstallationForm';
import ModalView from '../../Layout/ModalView/ModalView';
import { tableConfig } from '../../config';
import {
    formatRut, checkString, clearString,
} from '../../Service/Utils';
import { deleteInstallation, getInstallationsPhase } from '../../Service/Api';
import DownloadZipPdfs from '../../Components/DownloadZipPdfs/DownloadZipPdfs';


class Installation extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filteredData: [],
            loading: false,
            deleteId: null,
        };
        this.filteredData = [1];
        this.loadData = this.loadData.bind(this);
        this.findData = this.findData.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    findData = (id) => {
        const { data } = this.state;
        return data.find((item) => item.id === id);
    }

    formattedData = () => {
        const { filteredData } = this.state;
        const result = [];
        filteredData.forEach((item) => {
            result.push({
                'Fecha Instalacion': item.installation_date,
                Municipalidad: item.customer ? item.customer.name : '',
                // eslint-disable-next-line no-nested-ternary
                Estado: item.status === 'completed' ? 'Concluido' : (item.status === 'canceled' ? 'Cancelado' : 'Pendiente'),
                Formulario: item.forms ? item.forms.name : '',
                Item: item.item,
                'Nombre Cliente': item.client ? `${item.client.name}` : '',
                'Apellido Cliente': item.client ? `${item.client.last_name}` : '',
                'Rut Cliente': item.client ? item.client.rut : '',
                'Correo Cliente': item.client ? item.client.email : '',
                Calle: item.client && item.client.address ? item.client.address.street : '',
                Numero: item.client && item.client.address ? item.client.address.number : '',
                Comuna: item.client && item.client.address ? item.client.address.commune : '',
                Region: item.client && item.client.address ? item.client.address.region : '',
                'Nombre Tecnico': item.technical ? `${item.technical.name} ${item.technical.last_name}` : '',
            });
        });
        return result;
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        }
    }

    async loadData() {
        this.setState({
            loading: true,
        });

        const { match } = this.props;
        const { params } = match;
        const { id } = params;

        await getInstallationsPhase(id)
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        data: response.data,
                        filteredData: response.data,
                        loading: false,
                    });
                }
            }).catch((error) => {
                this.handleError(error);
            });
    }

    async removeClient() {
        const { deleteId } = this.state;
        await deleteInstallation(deleteId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    filterFunction = (rows, value, keys = 'name') => {
        const filtered = rows.filter((item) => (checkString(clearString(value), clearString(item[keys])) !== -1));
        return filtered;
    };

    getFilteredData = (filtered) => {
        const { data } = this.state;
        const filteredData = [];
        filtered.forEach((item) => {
            const f = data.find((element) => element.id === item.id);
            if (f) {
                filteredData.push(f);
            }
        });
        this.setState({ filteredData });
    }

    convertName(value) {
        switch (value) {
            case 'pending':
                return {
                    status: 'Pendiente',
                    color: 'orange',
                };
            case 'completed':
                return {
                    status: 'Concluido',
                    color: 'green',
                };
            default:
                return {
                    status: 'Cancelado',
                    color: 'red',
                };
        }
    }

    render() {
        const {
            data, loading,
        } = this.state;
        const columns = [
            {
                Header: '#',
                accessor: 'id',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>{row.original.id}</div>
                ),
                filterMethod: (filter, rows) => this.filterFunction(rows, filter.value, 'id'),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Cliente',
                accessor: 'client.name',
                Cell: (row) => (
                    <ModalView title={row.original.client.name}>
                        <ClientForm data={this.findData(row.original.id)} readOnly />
                    </ModalView>
                ),
                filterMethod: (filter, rows) => this.filterFunction(rows, filter.value, 'client.name'),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Rut',
                accessor: 'client.rut',
                maxWidth: 100,
                Cell: (row) => (
                    <span>{formatRut(row.original.client.rut)}</span>
                ),
                filterMethod: (filter, rows) => this.filterFunction(rows, filter.value, 'client.rut'),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Fecha Instalación',
                accessor: 'installation_date',
                maxWidth: 150,
                Cell: (row) => (
                    <span>{row.original.installation_date}</span>
                ),
                filterMethod: (filter, rows) => this.filterFunction(rows, filter.value, 'installation_date'),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Municipalidad',
                accessor: 'customer.name',
                Cell: (row) => <span>{row.original.customer.name}</span>,
                filterMethod: (filter, rows) => this.filterFunction(rows, filter.value, 'customer.name'),
                filterAll: true,
                filterable: true,
                show: true,
            },
            {
                Header: 'Status',
                id: 'status',
                accessor: (row) => this.convertName(row.status).status,
                Cell: (row) => <span style={{ textTransform: 'capitalize', fontWeight: 'bold', color: this.convertName(row.original.status).color }}>{this.convertName(row.original.status).status}</span>,
                filterMethod: (filter, rows) => this.filterFunction(rows, filter.value, 'status'),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Acciones',
                id: 'actions',
                Cell: (row) => (
                    <div className="form-actions">
                        {row.original.pdf !== null && (
                            <a href={`${process.env.REACT_APP_API_URL}/pdf/${row.original.pdf}`}><i className="far fa-file-pdf" /></a>
                        )}
                    </div>
                ),
                filterAll: false,
                filterable: false,
            },

        ];

        const { filteredData } = this.state;

        return (
            <>
                <TopBar>
                    <DownloadCSVButton data={this.formattedData()} filename="clientes.csv" />
                    <DownloadZipPdfs data={filteredData} />
                </TopBar>

                <ReactTable
                    data={data}
                    columns={columns}
                    {...tableConfig}
                    loading={loading}
                    ref={(r) => {
                        this.reactTableRef = r;
                    }}
                    onFilteredChange={() => {
                        this.getFilteredData(this.reactTableRef.getResolvedState().sortedData);
                    }}
                />

            </>
        );
    }
}

Installation.contextType = StateContext;

export default Installation;
