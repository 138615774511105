import React, { useState, useEffect } from 'react';
import matchSorter from 'match-sorter';
import Folder from '../../Components/Folder/Folder';
import Input from '../../Components/Input/Input';
import { getPhases } from '../../Service/Api';

import './Register.scss';

const Registers = () => {
    const [phases, setPhases] = useState([]);
    const [showPhases, setShowPhases] = useState([]);
    const [name, setName] = useState('');

    useEffect(() => {
        getPhases()
            .then((response) => {
                setPhases(response.data);
                setShowPhases(response.data);
            });
    }, []);

    const onChange = ({ target }) => {
        const { value } = target;
        setName(value);
        const sorted = matchSorter(phases, value, { keys: ['name'] });
        setShowPhases(sorted);
    };

    const rest = {
        onChange,
        autoComplete: 'false',
    };

    return (
        <div>
            <div className="search">
                <form autoComplete="off">
                    <Input name="autooff" autoComplete="false" type="hidden" onChange={() => {}} />
                    <Input name="name" label="Nombre" value={name} icon="fas fa-search" {...rest} />
                </form>
            </div>
            <div className="main">
                {showPhases && showPhases.map((item) => (
                    <div className="folder" key={item.id}>
                        <Folder data={item} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Registers;
