/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Row, Spinner } from 'reactstrap';
import Select from 'react-select';
import Button from '../../Components/Button/Button';
import { getCustomers, attachClientToCustomer } from '../../Service/Api';

class AttachCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            customers: [],
            customer: null,
            customStyles: {
                container: () => ({
                    width: '90%',
                    marginLeft: '5%',
                }),
                menu: () => ({
                    marginLeft: 0,
                    width: '100%',
                    position: 'relative',
                    border: '1px solid #dee2e6 !important',
                    borderTop: '0px',
                    borderRadius: '0 0 5px 5px',
                }),
            },
        };
    }

    componentDidMount() {
        const { data } = this.props;
        this.setState({ loading: true });
        getCustomers().then((response) => {
            this.setState({
                customers: this.createCustomers(response.data),
                customer: data.customers.length > 0 ? { value: data.customers[0].id, label: data.customers[0].name } : [],
            }, () => {
                this.setState({
                    loading: false,
                });
            });
        }).catch((error) => {
            this.handleError(error);
        });
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        }

        this.setState({ loading: false });
    }

    createCustomers = (customers) => {
        const data = [];
        customers.forEach((item) => {
            data.push({ value: item.id, label: item.name });
        });
        return data;
    }

    onChangeSelect = (data) => {
        this.setState({
            customer: data,
        });
    }

    handleSubmit = () => {
        const { data, callback } = this.props;
        const { customer } = this.state;
        if (customer) {
            attachClientToCustomer({ client: data.id, customer: customer.value })
                .then(() => {
                    callback();
                }).catch((error) => {
                    this.handleError(error);
                });
        } else {
            callback();
        }
    }


    render() {
        const {
            customStyles, loading, customers, customer,
        } = this.state;

        return (
            <>
                <Row>
                    {!loading && (
                        <Select
                            defaultValue={customer}
                            styles={customStyles}
                            options={customers}
                            onChange={this.onChangeSelect}
                        />
                    )}
                </Row>
                <div className="form-footer">
                    {loading && <div className="spinner"><Spinner /></div>}
                    <Button text="Asignar" onClick={this.handleSubmit} disabled={loading} />
                </div>
            </>
        );
    }
}


export default AttachCustomer;
