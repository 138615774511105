export default [

    {
        id: 1,
        title: 'Registros',
        path: '/registros',
        icon: 'far fa-clipboard',

    },
];
