/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, {
    useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import {
    Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import {
    PDFViewer, pdf,
} from '@react-pdf/renderer';
import Comments from '../../Components/Comments/Comments';
import Multiple from '../../Components/Multiple/Multiple';
import Photo from '../../Components/Photo/Photo';
import Simple from '../../Components/Simple/Simple';
import Input from '../../Components/Input/Input';
import Button from '../../Components/Button/Button';
import Doc from './Doc';
import { StateContext } from '../../State';
import { getForm, getCustomers, createForm } from '../../Service/Api';

import { checkString } from '../../Service/Utils';

import './Register.scss';
import FormComponent from '../../Components/FormComponent/FormComponent';
import Signature from '../../Components/Signature/Signature';
import Installation from '../../Components/Installation/Installation';
import SendModal from '../../Components/SendModal/SendModal';
import { AlertDialog } from '../../Components/Dialog/Dialog';

const Form = () => {
    const [form, setForm] = useState(null);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({});
    const [showing, setShowing] = useState(false);
    const [ready, setReady] = useState(false);
    const [json, setJson] = useState({});
    const [sign, setSign] = useState(null);
    const [signStatus, setSignStatus] = useState(null);
    const [formStatus, setFormStatus] = useState(null);
    const [forms, setForms] = useState([]);
    const [selectedForm, setSelectedForm] = useState(-1);
    const [dialog, setDialog] = useState({
        success:false,
        show:false,
        title: "error",
        message: "No fue posible concluir esta accion..."
    })
    const [personalData, setPersonalData] = useState({
        address: {
            street: '',
            number: '',
            commune: '',
            region: '',
        },
        name: '',
        last_name: '',
        rut: '',
        email: '',
        item: '',
    });
    const signPad = useRef(null);

    const [customers, setCustomers] = useState([]);
    const [phases, setPhases] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('-1');
    const [selectedPhase, setSelectedPhase] = useState('-1');
    const [loading, setLoading] = useState(false);
    const stateContext = useContext(StateContext);
    const [sendModal, setSendModal] = useState(false);

    const createFormErrorData = useCallback((responseForm) => {
        let fd = {}; // Form Data
        let er = {}; // Errors
        responseForm.model_section.forEach((item, index) => {
            item.model_field.forEach((f, indexField) => {
                const data = {};
                const errorData = {};
                data[`field-${indexField}-${index}`] = f.type === 'simple' ? false : '';
                data[`comment_field-${indexField}-${index}`] = '';
                errorData[`field-${indexField}-${index}`] = '';
                const changeField = { ...fd, ...data };
                fd = changeField;
                er = { ...er, ...errorData };
            });
        });
        setFormData(fd);
        setErrors(er);
    }, []);

    const formatInstallation = (i) => {
        const installations = [];
        i.forEach((item) => {
            item.name = `${item.name}`;
            installations.push(item);
        });
        return installations;
    };

    useEffect(() => {
        setLoading(true);
        getCustomers().then((response) => {
            const responseForm = response.data;
            setCustomers(formatInstallation(responseForm));
            setLoading(false);
        });
    }, [createFormErrorData]);


    const validDynamicForm = () => {
        let formIsValid = true;
        let errorsData = {};
        setFormStatus(true);

        const personalErrors = {};

        if (selectedPhase === '-1' || selectedPhase === -1) {
            personalErrors.etapa = ['Requerido'];
            formIsValid = false;
        }
        Object.keys(personalData).forEach((item) => {
            if (typeof personalData[item] === 'object') {
                Object.keys(personalData[item]).forEach((key) => {
                    if (!personalData[item][key] || personalData[item][key].trim() === '') {
                        personalErrors[key] = ['Requerido'];
                        formIsValid = false;
                    }
                });
            } else if (item !== 'email' && (!personalData[item] || personalData[item].trim() === '')) {
                personalErrors[item] = ['Requerido'];
                formIsValid = false;
            }
        });

        form.model_section.forEach((item, index) => {
            item.model_field.forEach((f, indexField) => {
                const field = f;
                if (field.required === 1 && formData[`field-${indexField}-${index}`] === '') {
                    formIsValid = false;
                    const er = {};
                    er[`field-${indexField}-${index}`] = ['Requerido'];
                    errorsData = { ...errorsData, ...er };
                }
            });
        });
        setErrors({ ...errors, ...errorsData, ...personalErrors });
        if (!formIsValid) setFormStatus(false);
        return formIsValid;
    };

    const onChangeField = ({ target }) => {
        const data = {};
        const er = {};
        switch (target.type) {
            case 'checkbox':
                data[target.name] = target.checked;
                er[target.name] = '';
                break;
            default:
                data[target.name] = target.value;
                er[target.name] = '';
        }
        const changeField = { ...formData, ...data };
        setFormData(changeField);
        const allErrors = { ...errors, ...er };
        setErrors(allErrors);
        const r = Object.keys(allErrors).find((key) => Array.isArray(allErrors[key]));
        if (r !== undefined) {
            setFormStatus(false);
        } else {
            setFormStatus(true);
        }
    };

    const getControl = (field, index, indexField) => {
        const comments = field.comments === 1;
        const props = {
            required: field.required === 1,
            name: `field-${indexField}-${index}`,
            label: field.name,
            onChange: onChangeField,
            value: formData[`field-${indexField}-${index}`],
            errors,
        };

        switch (field.type) {
            case 'multiple':
                return (
                    <>
                        <Multiple options={field.model_field_options} {...props} />
                        {comments && <Comments {...props} />}
                    </>
                );
            case 'image':
                return (
                    <>
                        <Photo {...props} />
                        {comments && <Comments {...props} />}
                    </>
                );
            case 'simple':
                return (
                    <>
                        <Simple {...props} />
                        {comments && <Comments {...props} />}
                    </>
                );
            case 'text':
                return (
                    <>
                        <Input {...props} />
                        {comments && <Comments {...props} />}
                    </>
                );
            default:
                return null;
        }
    };


    const createJsonData = async () => {
        if (validDynamicForm() && signStatus) {
            const data = JSON.parse(JSON.stringify(form));
            delete data.created_at;
            delete data.updated_at;
            delete data.deleted_at;
            delete data.model_form_id;

            const customer = customers.find((item) => parseInt(item.id) === parseInt(selectedCustomer));
            const [state] = stateContext;
            data.extraData = { client: personalData, technical: state.user, customer };

            Object.keys(formData).forEach((key) => {
                if (checkString('comment', key) === -1) {
                    const add = key.split('-');
                    data.model_section[add[2]].model_field[add[1]].value = formData[key];
                    data.model_section[add[2]].model_field[add[1]].comments = formData[`comment_${key}`];

                    let multipleText = '';
                    if (data.model_section[add[2]].model_field[add[1]].type === 'multiple') {
                        const findMultiple = data.model_section[add[2]].model_field[add[1]].model_field_options.find((itemBusca) => parseInt(itemBusca.id) === parseInt(formData[key]));
                        if (findMultiple !== undefined) {
                            multipleText = findMultiple.name;
                        }
                    }
                    data.model_section[add[2]].model_field[add[1]].multipleText = multipleText;
                }
            });
            setFormStatus(true);
            await setJson(data);
            return data;
        }
        if (!sign) await setSignStatus(false);
        return false;
    };

    const toggle = () => {
        if (form !== null) {
            createJsonData().then((response) => {
                if (response) {
                    setReady(false);
                    setShowing(!showing);
                    if (!showing) {
                        setReady(true);
                    }
                }
            });
        }
    };

    const handleSend = () => {
        if (form !== null) {
            createJsonData().then((response) => {
                if (response) {
                    setSendModal(true);
                    pdf(<Doc
                        form={response}
                        sign={sign}
                    />).toBlob()
                        .then((pdfBlob) => {
                            const reader = new window.FileReader();
                            reader.readAsDataURL(pdfBlob);
                            reader.onloadend = () => {
                                const base64data = reader.result;
                                response.pdf = base64data;
                                response.phase_id = selectedPhase;
                                response.selectedForm = form;
                                createForm(response)
                                    .then(() => {
                                        setSendModal(false);
                                        window.location.reload();
                                    })
                                    .catch((e) => {
                                        console.log(e.response);
                                        setSendModal(false);
                                        setDialog({...dialog,
                                            show: true,
                                            message: dialog.message + ' ' + e.response.data.message
                                        })
                                    });
                            };
                        });
                }
            })
        }
    };

    const clear = () => {
        signPad.current.clear();
        setSign(null);
        setSignStatus(false);
    };

    const onChangeSign = () => {
        setSignStatus(true);
        setSign(signPad.current.getTrimmedCanvas().toDataURL('image/png'));
    };

    const onChangeForm = ({ target }) => {
        setLoading(true);
        setSelectedForm(target.value);
        const f = forms.find((item) => parseInt(item.id) === parseInt(target.value));
        getForm(f.id).then((response) => {
            const responseForm = response.data;
            createFormErrorData(responseForm);
            setForm(responseForm);
            setLoading(false);
        });
    };

    const onChangeCustomer = ({ target }) => {
        const { value } = target;
        const c = customers.find((item) => parseInt(item.id) === parseInt(value));
        setSelectedCustomer(value);
        setPhases(c.phases);
        setSelectedPhase(c.phases.length > 0 ? c.phases[c.phases.length - 1].id : -1);
        setForms(c.attached_forms);
        const e = { ...errors };
        delete e.etapa;
        setErrors(e);
    };

    const onChangePhase = ({ target }) => {
        setSelectedPhase(target.value);
        const e = { ...errors };
        delete e.etapa;
        setErrors(e);
    };

    const onChange = (newData) => {
        const e = { ...errors };
        if (e[newData.action.field]) {
            delete e[newData.action.field];
            setErrors(e);
        }
        const d = { ...personalData, ...newData };
        setPersonalData(d);
    };


    return (
        <>
            <SendModal show={sendModal} />
            <AlertDialog title={dialog.title} message={dialog.message} show={dialog.show} onConfirm={() => setDialog({...dialog, show:false})} />
            <Installation
                customers={customers}
                onChangeCustomer={onChangeCustomer}
                selectedCustomer={selectedCustomer}
                errors={errors}
                loading={loading}
                phases={phases}
                selectedPhase={selectedPhase}
                onChangePhase={onChangePhase}

            />

            {/* Componente para renderizar Forms */}
            <FormComponent
                forms={forms}
                form={form}
                selectedForm={selectedForm}
                errors={errors}
                loadingForms={loading}
                getControl={getControl}
                onChangeForm={onChangeForm}
                onChange={onChange}
                data={personalData}
            />

            {/* Componente para rendizar firma */}
            <Signature
                onChangeSign={onChangeSign}
                signPad={signPad}
                clear={clear}

            />

            {form !== null && formStatus === false && (<div className="error-valid-form">* Faltan campos requerido</div>)}
            {form !== null && signStatus === false && (<div className="error-valid-form">* Debe firmar documento</div>)}


            <div className="form-footer">
                <Button text="Vista previa" onClick={toggle} />
            </div>

            <div className="form-footer">
                {/* {loading && <div className="spinner"><Spinner /></div>} */}
                <Button text="Enviar" onClick={handleSend} />
            </div>

            {/* Modal View PDF */}

            <Modal size="lg" isOpen={showing} toggle={toggle}>
                <ModalHeader toggle={toggle} />
                <ModalBody>
                    {ready && (
                        <PDFViewer width="100%" height="800px">
                            <Doc
                                form={json}
                                sign={sign}
                            />
                        </PDFViewer>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default Form;
