export default [
    {
        id: 1,
        title: 'Registros',
        path: '/registros',
        icon: 'fas fa-clipboard',
    },
    {
        id: 2,
        title: 'Registrar',
        path: '/admin/entrada',
        icon: 'far fa-calendar-check',

    },
];
