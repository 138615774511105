/* eslint-disable react/prop-types */
import React from 'react';

import './DownloadZipPdfs.scss';

const DownloadZipPdfs = (props) => {
    const { data } = props;

    const handleClick = () => {
        const ids = [];
        data.forEach((item) => {
            ids.push(item.id);
        });
        window.open(`${process.env.REACT_APP_API_URL}/pdf/zip/${ids}`, '_blank');
    };

    return (
        <button className="icon-button" type="button" onClick={handleClick}>
            <i className="fas fa-file-pdf" />
        </button>
    );
};

export default DownloadZipPdfs;
