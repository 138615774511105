/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spinner } from 'reactstrap';
import { StateContext } from '../../State';
import Input from '../../Components/Input/Input';
import Select from '../../Components/Select/Select';
import Button from '../../Components/Button/Button';
import { getCustomers, createPhase, updatePhase } from '../../Service/Api';

class PhasesForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createMode: true,
            customers: [],
            data: {},
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    componentDidMount() {
        const { data } = this.props;

        getCustomers().then((response) => {
            if (data) {
                this.setState({
                    data,
                    createMode: false,
                });
            }
            this.setState({
                customers: response.data,
            });
        });
    }

    onChange(event) {
        const { name, value } = event.target;
        const { data, errors } = this.state;
        data[name] = value;
        errors[name] = '';
        this.setState({
            data,
            errors,
        });
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        } else {
            this.setState({
                errors: error.response.data.errors,
            });
        }
    }

    validForm() {
        let formIsValid = true;

        const { data, errors } = this.state;
        const { name, customer_id } = data;
        if (!name || name.trim().length === 0) {
            formIsValid = false;
            errors.name = 'Requerido';
        }

        if (!customer_id || customer_id.trim().length === 0) {
            formIsValid = false;
            errors.customer_id = 'Requerido';
        }

        this.setState({ errors });

        return formIsValid;
    }

    async handleCreate() {
        if (this.validForm()) {
            this.toggleLoading(true);
            const { data } = this.state;
            await createPhase(data).then((response) => {
                if (response && response.status === 201) {
                    const { callback } = this.props;
                    callback();
                }
            }).catch((error) => {
                this.handleError(error);
            });

            this.toggleLoading(false);
        }
    }

    async handleUpdate() {
        if (this.validForm()) {
            this.toggleLoading(true);
            const { data } = this.state;
            await updatePhase(data.id, data).then((response) => {
                if (response && response.status === 200) {
                    const { callback } = this.props;
                    callback();
                }
            }).catch((error) => {
                this.handleError(error);
            });
            this.toggleLoading(false);
        }
    }

    toggleLoading(value) {
        this.setState({
            loading: value,
        });
    }

    render() {
        const { readOnly } = this.props;
        const {
            createMode, errors, data, loading, customers,
        } = this.state;
        const { name, customer_id } = data;


        const rest = {
            onChange: this.onChange,
            errors,
            required: true,
        };

        return (
            <>
                <Row>
                    <Col md={12}>
                        <Select label="Municipalidad" name="customer_id" options={customers} placeholder="Seleccione..." onChange={this.onChange} value={customer_id} errors={errors} required readOnly={readOnly} />
                    </Col>
                    <Col md={12}><Input name="name" label="Nombre" value={name} readOnly={readOnly} icon="fas fa-city" {...rest} /></Col>
                </Row>
                {!readOnly && (
                    <div className="form-footer">
                        {loading && <div className="spinner"><Spinner /></div>}
                        <Button text={createMode ? 'Crear' : 'Actualizar'} onClick={createMode ? this.handleCreate : this.handleUpdate} />
                    </div>
                )}
            </>
        );
    }
}

PhasesForm.propTypes = {
    callback: PropTypes.func,
    data: PropTypes.object,
    readOnly: PropTypes.bool,
    customer: PropTypes.number,
};

PhasesForm.defaultProps = {
    callback: null,
    data: null,
    readOnly: false,
    customer: null,
};

PhasesForm.contextType = StateContext;

export default PhasesForm;
