/* eslint-disable no-confusing-arrow */
import React, { Component } from 'react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import TopBar from '../../Components/TopBar/TopBar';
import { StateContext } from '../../State';
import DownloadCSVButton from '../../Components/DownloadCSVButton/DownloadCSVButton';
import CustomerForm from './CustomerForm';
import AttachForm from './AttachForm';
import ModalView from '../../Layout/ModalView/ModalView';
import { ConfirmDialog } from '../../Components/Dialog/Dialog';
import { tableConfig } from '../../config';
import { formatRut, formatPhone } from '../../Service/Utils';
import { getCustomers, deleteUser, restoreUser } from '../../Service/Api';

class Customer extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            showConfirm: false,
            showConfirmActivate: false,
            data: [],
            loading: false,
            deleteId: null,
            filteredData: [],
        };
        this.loadData = this.loadData.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.findData = this.findData.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    findData = (id) => {
        const { data } = this.state;
        return data.find((item) => item.id === id);
    }

    formattedData = () => {
        const { filteredData } = this.state;
        const result = [];
        filteredData.forEach((item) => {
            result.push({
                ID: item.id,
                Nombre: `${item.name} ${item.last_name}`,
                Rut: item.rut,
                Correo: item.email,
                Telefono: item.phone,
                Username: item.username,
            });
        });
        return result;
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        }
    }

    getFilteredData = (filtered) => {
        const { data } = this.state;
        const filteredData = [];
        filtered.forEach((item) => {
            const f = data.find((element) => element.id === item.id);
            if (f) {
                filteredData.push(f);
            }
        });
        this.setState({ filteredData });
    }

    async loadData() {
        this.setState({
            loading: true,
        });

        await getCustomers()
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        data: response.data,
                        filteredData: response.data,
                        loading: false,
                    });
                }
            }).catch((error) => {
                this.handleError(error);
            });
    }

    async removeClient() {
        const { deleteId } = this.state;
        await deleteUser(deleteId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    async ActivateClient() {
        const { deleteId } = this.state;
        await restoreUser(deleteId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    handleRestore(id) {
        this.setState({
            showConfirmActivate: true,
            deleteId: id,
        });
    }

    handleRemove(id) {
        this.setState({
            showConfirm: true,
            deleteId: id,
        });
    }

    render() {
        const { showConfirm, data, loading, showConfirmActivate } = this.state;
        const [{ role }] = this.context;

        const columns = [
            {
                Header: '#',
                accessor: 'id',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>{row.original.id}</div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['id'] }),
                width: 50,
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Nombre',
                accessor: 'name',
                Cell: (row) => (
                    <ModalView title={row.original.name}>
                        <CustomerForm data={this.findData(row.original.id)} readOnly />
                    </ModalView>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['name'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Rut',
                accessor: 'rut',
                maxWidth: 100,
                Cell: (row) => (
                    <span>{formatRut(row.original.rut)}</span>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['rut'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Teléfono de contacto',
                accessor: 'phone',
                maxWidth: 150,
                Cell: (row) => (
                    <a href={`tel:${row.original.phone}`}>{formatPhone(row.original.phone)}</a>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['phone'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Email',
                accessor: 'email',
                Cell: (row) => (
                    <a href={`mailto:${row.original.email}`}>
                        {row.original.email}
                    </a>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['email'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Status',
                accessor: (row) => row.deleted_at ? 'Inactivo' : 'Activo',
                id: 'status',
                Cell: (row) => (
                    <span>{row.original.deleted_at ? 'Inactivo' : 'Activo'}</span>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['status'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Acciones',
                id: 'actions',
                filterable: false,
                sortable: false,
                maxWidth: 100,
                Cell: (row) => (
                    <div className="form-actions">
                        {role.role_number === 0 && (
                            <ModalView title="Asignar Formulario" type="attach" callback={this.loadData}>
                                <AttachForm data={this.findData(row.original.id)} />
                            </ModalView>
                        )}
                        <ModalView title="Editar cliente" type="edit" callback={this.loadData}>
                            <CustomerForm data={this.findData(row.original.id)} />
                        </ModalView>
                        {
                            !row.original.deleted_at && (
                                <button onClick={() => { this.handleRemove(row.original.id); }} type="button">
                                    <i className="fas fa-user-slash" />
                                </button>
                            )
                        }
                        {
                            row.original.deleted_at && (
                                <button onClick={() => { this.handleRestore(row.original.id); }} type="button">
                                    <i className="fas fa-clipboard-check" />
                                </button>
                            )
                        }
                    </div>
                ),
            },
        ];

        return (
            <>
                <TopBar>
                    <DownloadCSVButton data={this.formattedData()} filename="municipalidad.csv" />
                    <ModalView title="Crear cliente" type="add" callback={this.loadData}>
                        <CustomerForm />
                    </ModalView>
                </TopBar>

                <ReactTable
                    data={data}
                    columns={columns}
                    {...tableConfig}
                    loading={loading}
                    ref={(r) => {
                        this.reactTableRef = r;
                    }}
                    onFilteredChange={() => {
                        this.getFilteredData(this.reactTableRef.getResolvedState().sortedData);
                    }}
                />

                <ConfirmDialog
                    show={showConfirm}
                    title="Desactivar Municipalidad"
                    onConfirm={() => {
                        this.removeClient();
                        this.setState({ showConfirm: false });
                    }}
                    onCancel={() => {
                        this.setState({ showConfirm: false });
                    }}
                />

                <ConfirmDialog
                    show={showConfirmActivate}
                    title="Restaurar Municipalidad"
                    onConfirm={() => {
                        this.ActivateClient();
                        this.setState({ showConfirmActivate: false });
                    }}
                    onCancel={() => {
                        this.setState({ showConfirmActivate: false });
                    }}
                />
            </>
        );
    }
}

Customer.contextType = StateContext;

export default Customer;
