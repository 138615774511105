/* eslint-disable react/sort-comp */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spinner } from 'reactstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import { StateContext } from '../../State';
import Input from '../../Components/Input/Input';
import Select from '../../Components/Select/Select';
import Button from '../../Components/Button/Button';
import {
    getInstallationFormData, getFormAndClients, createInstallation,
} from '../../Service/Api';

import 'react-datetime/css/react-datetime.css';

import 'moment/locale/es';

moment().format();

class InstallationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            clients: [],
            forms: [],
            technicals: [],
            phases: [],
            data: {
                installation_date: moment().format('YYYY-MM-DD HH:mm:ss'),
            },
            errors: {},
            loadingData: true,
        };
        this.handleCreate = this.handleCreate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.validForm = this.validForm.bind(this);
    }

    componentDidMount() {
        getInstallationFormData()
            .then((response) => {
                this.setState({
                    customers: response.data.customers,
                    technicals: response.data.technicals,
                    loadingData: false,
                });
            })
            .catch((error) => {
                this.handleError(error);
            });
    }


    onChange(event) {
        const { name, value } = event.target;
        const { data, errors } = this.state;
        data[name] = value;
        errors[name] = '';
        this.setState({
            data,
            errors,
        });

        if (name === 'customer_id') {
            this.setState({ loadingData: true });
            getFormAndClients(value)
                .then((response) => {
                    this.setState({
                        forms: response.data.attached_forms,
                        phases: response.data.phases,
                        clients: response.data.clients,
                        loadingData: false,
                    });
                })
                .catch((error) => {
                    this.handleError(error);
                });
        }
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        } else {
            this.setState({
                errors: error.response.data.errors,
            });
        }
    }

    validForm() {
        const { data } = this.state;
        const {
            item,
            customer_id,
            client_id,
            installation_date,
            model_form_id,
            technical_id,
            phase_id,
        } = data;

        const [{ role }] = this.context;
        const errors = {};
        let formIsValid = true;

        if (!item || item.trim().length === 0) {
            formIsValid = false;
            errors.item = 'Requerido';
        }

        if (!customer_id || customer_id.trim().length === 0) {
            formIsValid = false;
            errors.customer_id = 'Requerido';
        }

        if (!client_id || client_id.trim().length === 0) {
            formIsValid = false;
            errors.client_id = 'Requerido';
        }

        if (!installation_date || installation_date.trim().length === 0) {
            formIsValid = false;
            errors.installation_date = 'Requerido';
        }

        if (!model_form_id || model_form_id.trim().length === 0) {
            formIsValid = false;
            errors.model_form_id = 'Requerido';
        }

        if (role.id === 1) {
            if (!technical_id || technical_id.trim().length === 0) {
                formIsValid = false;
                errors.technical_id = 'Requerido';
            }
        }

        if (!phase_id || phase_id.trim().length === 0) {
            formIsValid = false;
            errors.phase_id = 'Requerido';
        }

        this.setState({
            errors,
        });

        return formIsValid;
    }

    async handleCreate() {
        const [{ user, role }] = this.context;
        if (this.validForm()) {
            this.toggleLoading(true);
            const { data } = this.state;
            const { callback } = this.props;
            if (role.id !== 1) {
                data.technical_id = user.id;
            }
            createInstallation(data)
                .then(() => {
                    this.toggleLoading(false);
                    callback();
                })
                .catch((error) => {
                    this.toggleLoading(false);
                    this.handleError(error);
                });
        }
    }

    toggleLoading(value) {
        this.setState({
            loading: value,
        });
    }

    handleDate = (e) => {
        const installation_date = e.format('YYYY-MM-DD HH:mm:ss');
        const { data } = this.state;
        data.installation_date = installation_date;
        this.setState({ data });
    }

    render() {
        const {
            errors, data, loading, customers, clients, forms, technicals, loadingData, phases,
        } = this.state;
        const {
            item,
            customer_id,
            client_id,
            installation_date,
            model_form_id,
            technical_id,
            phase_id,
        } = data;

        const rest = {
            onChange: this.onChange,
            errors,
            required: true,
        };

        const [{ role }] = this.context;

        return (
            <>
                <Row>
                    <Col md={12}><Input name="item" label="Item" value={item} icon="fas fa-industry" {...rest} /></Col>
                    <Col md={12}>
                        <Select label="Municipalidad" name="customer_id" options={customers} placeholder="Seleccione..." onChange={this.onChange} value={customer_id} errors={errors} required loading={loadingData} />
                    </Col>
                    <Col md={12}>
                        <Select label="Etapa" name="phase_id" options={phases} placeholder="Seleccione..." onChange={this.onChange} value={phase_id} errors={errors} required loading={loadingData} />
                    </Col>
                    <Col md={12}>
                        <Select label="Cliente" name="client_id" options={clients} placeholder="Seleccione..." onChange={this.onChange} value={client_id} errors={errors} required loading={loadingData} />
                    </Col>
                    <Col md={12}>
                        <Select label="Formulario" name="model_form_id" options={forms} placeholder="Seleccione..." onChange={this.onChange} value={model_form_id} errors={errors} required loading={loadingData} />
                    </Col>

                    {role.id === 1 && (
                        <Col md={12}>
                            <Select label="Tecnico" name="technical_id" options={technicals} placeholder="Seleccione..." onChange={this.onChange} value={technical_id} errors={errors} required loading={loadingData} />
                        </Col>
                    )}

                    <Col md={12}>
                        <Datetime
                            value={moment(installation_date)}
                            onChange={this.handleDate}
                            dateFormat="DD/MM/YYYY"
                            timeFormat="HH:mm:ss"
                            locale="es"
                            name="installation_date"
                            required
                        />
                    </Col>
                </Row>
                <div className="form-footer">
                    {loading && <div className="spinner"><Spinner /></div>}
                    <Button text="Crear" onClick={this.handleCreate} />
                </div>
            </>
        );
    }
}

InstallationForm.propTypes = {
    callback: PropTypes.func,
    data: PropTypes.object,
    readOnly: PropTypes.bool,
};

InstallationForm.defaultProps = {
    callback: null,
    data: null,
    readOnly: false,
};

InstallationForm.contextType = StateContext;

export default InstallationForm;
