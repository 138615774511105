/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import { Row, Col } from 'reactstrap';
import Title from '../Title/Title';
import Select from '../Select/Select';
import Input from '../Input/Input';


const FormComponent = (props) => {
    const {
        forms, form, selectedForm, errors, loadingForms, getControl, onChangeForm, data, onChange,
    } = props;

    const {
        name, last_name, rut, email, address, item
    } = data;

    const {
        street, number, region, commune,
    } = address;

    const handleOnChange = (event, accesor = null) => {
        const { target } = event;
        const { name, value } = target;
        data.action = { field: name };
        if (accesor) {
            data[accesor][name] = value;
            onChange(data);
        } else {
            data[name] = value;
            onChange(data);
        }
    };

    return (
        <>

            <Row>
                <Col md={12}>
                    <Select
                        name="formulario"
                        required
                        label="Formulario"
                        options={forms}
                        placeholder="Seleccione..."
                        onChange={onChangeForm}
                        value={selectedForm}
                        errors={errors}
                        loading={loadingForms}
                    />
                </Col>
            </Row>


            {form && (
                <>
                    <Row>
                        <Col md={12}>
                            <Input placeholder="Producto a instalar" name="item" label="Producto" value={item} icon="fas fa-user" onChange={(event) => handleOnChange(event)} errors={errors} required />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Input name="name" label="Nombre" value={name} icon="fas fa-user" onChange={(event) => handleOnChange(event)} errors={errors} required />
                        </Col>
                        <Col md={6}>
                            <Input name="last_name" label="Apellido" value={last_name} icon="fas fa-user" onChange={(event) => handleOnChange(event)} errors={errors} required />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Input name="rut" label="Rut" mask="rut" value={rut} icon="fas fa-address-card" onChange={(event) => handleOnChange(event)} errors={errors} required />
                        </Col>
                        <Col md={6}>
                            <Input name="email" label="Email" value={email} icon="fas fa-envelope" onChange={(event) => handleOnChange(event)} errors={errors} required />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Input name="street" label="Calle" value={street} icon="fas fa-map-marked-alt" onChange={(event) => handleOnChange(event, 'address')} errors={errors} required />
                        </Col>
                        <Col md={6}>
                            <Input name="number" label="Numero" placeholder="Numero Casa/Depto" value={number} icon="fas fa-map-marked-alt" onChange={(event) => handleOnChange(event, 'address')} errors={errors} required />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Input name="commune" label="Comuna" value={commune} icon="fas fa-map-marked-alt" onChange={(event) => handleOnChange(event, 'address')} errors={errors} required />
                        </Col>
                        <Col md={6}>
                            <Input name="region" label="Region" value={region} icon="fas fa-map-marked-alt" onChange={(event) => handleOnChange(event, 'address')} errors={errors} required />
                        </Col>
                    </Row>
                </>
            )}

            {form && form.model_section.map((section, indexSection) => (
                <div key={`section${section.id}`}>
                    <Row className="register-container__section" key={`section${section.id}`}>
                        <Col md={12}>
                            {section.name && <Title text={section.name} />}
                        </Col>

                        {section.model_field.map((field, indexField) => (
                            <Col md={6} key={`field${field.id}`} className="register-container__field">
                                {getControl(field, indexSection, indexField)}
                            </Col>
                        ))}

                    </Row>

                </div>
            ))}
        </>
    );
};

export default FormComponent;
