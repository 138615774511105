/* eslint-disable react/prop-types */
import React from 'react';
import { Spinner } from 'reactstrap';

import './SendModal.scss';

const SendModal = (props) => {
    const { show } = props;

    return (
        <>
            {show && (
                <div className="sendmodal-content">
                    <div className="sendmodal-body">
                        <span>Espere mientras se crea el formulario...</span>
                        <Spinner style={{ width: '2rem', height: '2rem' }} />
                    </div>
                </div>
            )}
        </>
    );
};

export default SendModal;
