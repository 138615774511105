/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Row } from 'reactstrap';
import Select from '../Select/Select';

const Installation = (props) => {
    const {
        customers, onChangeCustomer, selectedCustomer, errors, loading, phases, selectedPhase, onChangePhase,
    } = props;

    return (
        <>
            <Row>
                <Col md={12}>
                    <Select
                        name="customer"
                        required
                        label="Municipalidad"
                        options={customers}
                        placeholder="Seleccione..."
                        onChange={onChangeCustomer}
                        value={selectedCustomer}
                        errors={errors}
                        loading={loading}
                    />
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <Select
                        name="etapa"
                        required
                        label="Etapa"
                        options={phases}
                        placeholder="Seleccione..."
                        onChange={onChangePhase}
                        value={selectedPhase}
                        errors={errors}
                        loading={loading}
                    />
                </Col>
            </Row>
        </>
    );
};

export default Installation;
