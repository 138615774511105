import React, { Component } from 'react';
import PropTypes from 'prop-types';
import matchSorter from 'match-sorter';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { StateContext } from '../../State';
import {
    getFormsDraft, deleteForm, copyForm, versionUpdate,
} from '../../Service/Api';
import { ConfirmDialog } from '../../Components/Dialog/Dialog';
import { tableConfig } from '../../config';
import './Draft.scss';

class Draft extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            showConfirm: false,
            showConfirmVersion: false,
            data: [],
            loading: false,
            deleteId: null,
            versionId: null,
        };
        this.loadData = this.loadData.bind(this);
        this.handleCopy = this.handleCopy.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.onViewClick = this.onViewClick.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onViewClick(data) {
        const { history } = this.props;
        if (data.versions === null) {
            history.push(`/admin/formularios/${data.id}`);
        } else {
            history.push(`/admin/formularios/${data.versions.id}`);
        }
    }

    findData = (id) => {
        const { data } = this.state;
        return data.find((item) => item.id === id);
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        }
    }

    handleNewVersion = (id) => {
        this.setState({
            showConfirmVersion: true,
            versionId: id,
        });
    }

    setVersion = async () => {
        const { versionId } = this.state;

        await versionUpdate({ id: versionId })
            .then(() => {
                this.loadData();
            });
    }

    async loadData() {
        this.setState({
            loading: true,
        });
        await getFormsDraft()
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        data: response.data,
                        loading: false,
                    });
                }
            }).catch((error) => {
                this.handleError(error);
            });
    }

    async removeForm() {
        const { deleteId } = this.state;
        await deleteForm(deleteId)
            .then((response) => {
                if (response && response.status === 200) {
                    this.loadData();
                }
            }).catch((error) => {
                this.handleError(error);
            });
    }

    async handleCopy(id) {
        const [, dispatch] = this.context;
        dispatch({
            type: 'SET_LOADING',
            value: true,
        });

        await copyForm(id)
            .then((response) => {
                if (response && response.status === 200) {
                    this.loadData();
                }
            }).catch((error) => {
                this.handleError(error);
            });

        dispatch({
            type: 'SET_LOADING',
            value: false,
        });
    }

    handleRemove(id) {
        this.setState({
            showConfirm: true,
            deleteId: id,
        });
    }

    render() {
        const {
            showConfirm, showConfirmVersion, data, loading,
        } = this.state;
        const columns = [
            {
                Header: 'Nombre',
                accessor: 'name',
                Cell: (row) => (
                    <button className="link-button" onClick={() => { this.onViewClick(row.original); }} type="button">
                        {row.original.name}
                    </button>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['name'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Acciones',
                id: 'actions',
                filterable: false,
                sortable: false,
                maxWidth: 150,
                Cell: (row) => (
                    <div className="form-actions">
                        <button onClick={() => { this.handleNewVersion(row.original.model_form_id); }} type="button">
                            <i className="fas fa-clipboard-check" />
                        </button>
                        <button onClick={() => { this.handleRemove(row.original.id); }} type="button">
                            <i className="fas fa-trash" />
                        </button>
                    </div>
                ),
            },
        ];

        return (
            <>
                <ReactTable
                    data={data}
                    columns={columns}
                    {...tableConfig}
                    loading={loading}
                />

                <ConfirmDialog
                    show={showConfirm}
                    title="Eliminar campo"
                    onConfirm={() => {
                        this.removeForm();
                        this.setState({ showConfirm: false });
                    }}
                    onCancel={() => {
                        this.setState({ showConfirm: false });
                    }}
                />

                <ConfirmDialog
                    show={showConfirmVersion}
                    title="Confirmar Cambios"
                    message="Al confirmar los cambios, se generará una nueva versión del formulario y se asociará con los respectivos clientes. Esta acción no tendrá retorno. Estás seguro de que quieres continuar?"
                    onConfirm={() => {
                        this.setVersion();
                        this.setState({ showConfirmVersion: false });
                    }}
                    onCancel={() => {
                        this.setState({ showConfirmVersion: false });
                    }}
                />
            </>
        );
    }
}

Draft.contextType = StateContext;

Draft.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }),
};

Draft.defaultProps = {
    match: {
        path: '',
    },
};


export default withRouter(Draft);
