/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import folder from './folder.svg';

import './Folder.scss';

const Folder = (props) => {
    const { data } = props;
    const { name, id } = data;

    const history = useHistory();

    const goTo = () => {
        history.push(`/registros/${id}`);
    };

    return (
        <div className="main" onClick={goTo}>
            <div className="img">
                <img src={folder} alt="folder" />
            </div>
            <div className="title">
                {name}
            </div>
        </div>
    );
};

Folder.propTypes = {
    data: PropTypes.object.isRequired,
};

Folder.defaultProps = {

};

export default Folder;
