import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Document, Page, Text, View, StyleSheet, Image,
} from '@react-pdf/renderer';
import logo from './logo.png';

class Doc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            styles: StyleSheet.create({

                container: {
                    flex: 1,
                    flexDirection: 'row',
                },

                page: {
                    backgroundColor: '#FFF',
                    paddingLeft: 40,
                    paddingRight: 40,
                    paddingBottom: 40,
                },

                title: {
                    marginTop: 10,
                    fontSize: 20,
                    fontWeight: 700,
                    textAlign: 'center',
                    textDecoration: 'underline',
                    marginLeft: 10,
                },

                date: {
                    marginTop: 20,
                    fontSize: 10,
                    textAlign: 'right',
                    marginRight: 10,
                },

                subtitle: {
                    marginTop: 10,
                    fontSize: 16,
                    fontWeight: 700,
                    textDecoration: 'underline',
                    marginLeft: 20,
                },

                logo: {
                    width: 100,
                    marginLeft: 10,
                },

                sign: {
                    width: 100,
                    marginTop: 10,
                    marginLeft: 10,
                    marginBottom: 10,
                },

                text: {
                    margin: 12,
                    fontSize: 14,
                    textAlign: 'justify',
                },

                item: {
                    fontSize: 14,
                    paddingBottom: 4,
                },

                group: {
                    paddingTop: 20,
                    padding: 10,
                    fontSize: 14,
                    border: '2pt solid #8c8c8c',
                    borderRadius: 5,
                    margin: 10,
                },

                field: {
                    paddingLeft: 20,
                    fontSize: 14,
                },

                fieldNotes: {
                    paddingLeft: 30,
                    fontSize: 14,
                },

                fieldContainer: {
                    paddingTop: 10,
                },

                image: {
                    marginTop: 10,
                    marginBottom: 10,
                    width: 200,
                    marginHorizontal: 0,
                },

                pageNumber: {
                    position: 'absolute',
                    fontSize: 12,
                    bottom: 30,
                    left: 0,
                    right: 30,
                    textAlign: 'right',
                    color: 'grey',
                },

                row: {
                    flex: 1,
                    flexDirection: 'row',
                    flexGrow: 1,
                    flexWrap: true,
                },

                column: {
                    width: '50%',
                    padding: 10,
                },

                line: {
                    border: '2pt solid #FF5900',
                    marginTop: 40,
                },
                pdfLine: {
                    fontSize: 10,
                    flex: 1,
                    flexDirection: 'row',
                    borderBottom: '1pt solid #8c8c8c',
                    paddingTop: 5,
                    paddingBottom: 2,
                },
                pdfLineNoBorder: {
                    fontSize: 10,
                    flex: 1,
                    flexDirection: 'row',
                    paddingTop: 5,
                    paddingBottom: 2,
                },
                pdfLineObs: {
                    fontSize: 10,
                    flex: 1,
                    flexDirection: 'row',
                    borderBottom: '1pt solid #8c8c8c',
                    paddingTop: 5,
                    paddingBottom: 2,
                    paddingLeft: 15,
                },
                pdfLineLabel: {
                    width: '20%',
                },
            }),
        };
    }

    componentDidMount() {
        console.log(this.props);
    }

    getField(field) {
        const { styles } = this.state;
        const data = field.value;

        if (field.type === 'simple') {
            return (
                <>
                    {field.comments !== '' && (
                        <>
                            <View style={styles.pdfLineNoBorder}>
                                <Text>
                                    {`${field.name}: ${data ? 'SI' : 'NO'}`}
                                </Text>
                            </View>
                            <View style={styles.pdfLineObs}>
                                <Text>
                                    {`* Observación: ${field.comments}`}
                                </Text>
                            </View>
                        </>
                    )}

                    {field.comments === '' && (
                        <View style={styles.pdfLine}>
                            <Text>
                                {`${field.name}: ${data ? 'SI' : 'NO'}`}
                            </Text>
                        </View>
                    )}
                </>
            );
        }

        if (data === '') return null;

        if (field.type === 'multiple') {
            return (
                <>
                    {field.comments !== '' && (
                        <>
                            <View style={styles.pdfLineNoBorder}>
                                <Text>
                                    {`${field.name}: ${field.multipleText}`}
                                </Text>
                            </View>
                            <View style={styles.pdfLineObs}>
                                <Text>
                                    {`* Observación: ${field.comments}`}
                                </Text>
                            </View>
                        </>
                    )}

                    {field.comments === '' && (
                        <View style={styles.pdfLine}>
                            <Text>
                                {`${field.name}: ${field.multipleText}`}
                            </Text>
                        </View>
                    )}
                </>
            );
        }

        if (field.type === 'image') {
            return (
                <>
                    {field.comments !== '' && (
                        <>
                            <View style={styles.pdfLineNoBorder}>
                                <Text>
                                    {`${field.name}`}
                                </Text>
                            </View>
                            <View style={styles.pdfLineObs}>
                                <Text>
                                    {`* Observación: ${field.comments}`}
                                </Text>
                            </View>
                        </>
                    )}

                    {field.comments === '' && (
                        <View style={styles.pdfLine}>
                            <Text>
                                {`${field.name}`}
                            </Text>
                        </View>
                    )}

                    <Image style={styles.image} src={data} />
                </>
            );
        }

        return (
            <>
                {field.comments !== '' && (
                    <>
                        <View style={styles.pdfLineNoBorder}>
                            <Text>
                                {`${field.name}: ${data}`}
                            </Text>
                        </View>
                        <View style={styles.pdfLineObs}>
                            <Text>
                                {`* Observación: ${field.comments}`}
                            </Text>
                        </View>
                    </>
                )}

                {field.comments === '' && (
                    <View style={styles.pdfLine}>
                        <Text>
                            {`${field.name}: ${data}`}
                        </Text>
                    </View>
                )}
            </>
        );
    }

    currentDate = () => {
        const date = new Date();
        const min = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
        const sec = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
        let string = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}\n`;
        string += `${date.getHours()}:${min}:${sec}`;
        return string;
    }

    render() {
        const {
            form, sign,
        } = this.props;

        const { styles } = this.state;
        // const title = `Informe de ${type === 'checkin' ? 'llegada' : 'salida'} de equipo`;
        const now = this.currentDate();
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.line}>

                        {/* Date Time and Logo */}
                        <Text style={styles.date} fixed>
                            {now}
                        </Text>
                        <Image
                            style={styles.logo}
                            src={logo}
                            fixed
                        />

                        {/* Header */}

                        <View style={styles.group}>

                            <View style={styles.pdfLine}>
                                <Text style={styles.pdfLineLabel}>
                                    Nombre:
                                </Text>
                                <Text>
                                    {`${form.extraData.client.name} ${form.extraData.client.last_name}`}
                                </Text>
                            </View>

                            <View style={styles.pdfLine}>
                                <Text style={styles.pdfLineLabel}>
                                    Dirección:
                                </Text>
                                <Text>
                                    {`${form.extraData.client.address.street} ${form.extraData.client.address.number}, ${form.extraData.client.address.commune}, ${form.extraData.client.address.region}`}
                                </Text>
                            </View>

                            <View style={styles.pdfLine}>
                                <Text style={styles.pdfLineLabel}>
                                    Email:
                                </Text>
                                <Text>
                                    {`${form.extraData.client.email}`}
                                </Text>
                            </View>

                            <View style={styles.pdfLine}>
                                <Text style={styles.pdfLineLabel}>
                                    Municipalidad:
                                </Text>
                                <Text>
                                    {`${form.extraData.customer.name}`}
                                </Text>
                            </View>

                            <View style={styles.pdfLine}>
                                <Text style={styles.pdfLineLabel}>
                                    Tecnico:
                                </Text>
                                <Text>
                                    {`${form.extraData.technical.name} ${form.extraData.technical.last_name}`}
                                </Text>
                            </View>

                            <View style={styles.pdfLine}>
                                <Text style={styles.pdfLineLabel}>
                                    Producto:
                                </Text>
                                <Text>
                                    {`${form.extraData.client.item}`}
                                </Text>
                            </View>

                            {/* <View style={styles.pdfLine}>
                                <Text style={styles.pdfLineLabel}>
                                    Formulario
                                </Text>
                                <Text>
                                    {`${form.name}`}
                                </Text>
                            </View> */}
                        </View>

                        {form.model_section.map((section) => (
                            <View key={section.id}>

                                <View style={styles.group}>
                                    <View style={styles.pdfLine}>
                                        <Text>
                                            {`${section.name}`}
                                        </Text>
                                    </View>

                                    {section.model_field.map((field) => (
                                        <View class={styles.pdfLine} key={field.id}>
                                            {this.getField(field)}
                                        </View>
                                    ))}

                                </View>
                            </View>
                        ))}

                        <View wrap={false}>
                            <Text style={styles.subtitle}>
                                Firma:
                            </Text>
                            <Image
                                style={styles.sign}
                                src={sign}
                            />
                        </View>

                    </View>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => (
                            `Página ${pageNumber} de ${totalPages}`
                        )}
                        fixed
                    />
                </Page>
            </Document>
        );
    }
}

Doc.propTypes = {
    form: PropTypes.object.isRequired,
    sign: PropTypes.string.isRequired,
};

export default Doc;
