/* eslint-disable react/prop-types */
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Button from '../Button/Button';

const Signature = (props) => {
    const { onChangeSign, signPad, clear } = props;

    return (
        <>
            <div>Firma *</div>
            <SignatureCanvas
                penColor="blue"
                canvasProps={{
                    width: 500,
                    height: 200,
                    className: 'signature',
                }}
                onEnd={onChangeSign}
                clearOnResize={false}
                ref={signPad}
            />
            <div style={{ width: '100px' }}>
                <Button text="Borrar" onClick={clear} />
            </div>
        </>
    );
};

export default Signature;
