/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spinner } from 'reactstrap';
import { StateContext } from '../../State';
import Input from '../../Components/Input/Input';
import Button from '../../Components/Button/Button';
import {
    validateEmail, validatePassword, formatRut, unformatRut,
    formatPhone, unformatPhone,
} from '../../Service/Utils';
import { addUser, updateUser } from '../../Service/Api';

class CustomerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createMode: true,
            data: {
                address: {},
            },
            errors: {},
        };
        this.handleCreate = this.handleCreate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.validForm = this.validForm.bind(this);
        this.onBlurRut = this.onBlurRut.bind(this);
        this.onFocusRut = this.onFocusRut.bind(this);
        this.onBlurPhone = this.onBlurPhone.bind(this);
        this.onFocusPhone = this.onFocusPhone.bind(this);
    }

    componentDidMount() {
        const { data } = this.props;
        if (data) {
            data.phone = formatPhone(data.phone);
            data.rut = formatRut(data.rut);
            this.setState({
                data,
                createMode: false,
            });
        }
    }

    onBlurRut() {
        const { data } = this.state;
        data.rut = formatRut(data.rut);
        this.setState({
            data,
        });
    }

    onFocusRut() {
        const { data } = this.state;
        data.rut = unformatRut(data.rut);
        this.setState({
            data,
        });
    }

    onBlurPhone() {
        const { data } = this.state;
        data.contact = formatPhone(data.contact);
        this.setState({
            data,
        });
    }

    onFocusPhone() {
        const { data } = this.state;
        data.contact = unformatPhone(data.contact);
        this.setState({
            data,
        });
    }

    onChange(event) {
        const { name, value } = event.target;
        const { data, errors } = this.state;
        data[name] = value;
        errors[name] = '';
        this.setState({
            data,
            errors,
        });
    }

    onChangeAddress(event) {
        const { name, value } = event.target;
        const { data, errors } = this.state;
        const { address } = data;
        address[name] = value;
        data.address = address;
        errors[name] = '';
        this.setState({
            data,
            errors,
        });
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        } else {
            const errors = [];
            const { data } = error.response;
            Object.keys(data).forEach((key) => {
                errors[key] = data[key][0];
            });
            this.setState({ errors });
        }
    }

    validForm() {
        const { data, createMode } = this.state;
        const {
            name, rut, email, address, username, phone, password,
        } = data;
        const errors = {};
        let formIsValid = true;

        if (!name || name.trim().length === 0) {
            formIsValid = false;
            errors.name = 'Requerido';
        }

        // if (!last_name || last_name.trim().length === 0) {
        //     formIsValid = false;
        //     errors.last_name = 'Requerido';
        // }

        if (!username || username.trim().length === 0) {
            formIsValid = false;
            errors.username = 'Requerido';
        }

        if (!phone || phone.trim().length === 0) {
            formIsValid = false;
            errors.phone = 'Requerido';
        }

        // if (rut && !validateRut(rut)) {
        //     formIsValid = false;
        //     errors.rut = 'Rut inválido';
        // }

        if (!rut || rut.trim().length === 0) {
            formIsValid = false;
            errors.rut = 'Requerido';
        }

        if (email && !validateEmail(email)) {
            formIsValid = false;
            errors.email = 'Email inválido';
        }

        if (!email || email.trim().length === 0) {
            formIsValid = false;
            errors.email = ['Requerido'];
        } else if (!validateEmail(email)) {
            formIsValid = false;
            errors.email = 'Error de formato de email';
        }

        if (createMode) {
            if (!password || password.trim().length === 0) {
                formIsValid = false;
                errors.password = ['Requerido'];
            } else if (!validatePassword(password)) {
                formIsValid = false;
                errors.password = 'Error de formato de password';
            }
        }

        if (!address.street || address.street.trim().length === 0) {
            formIsValid = false;
            errors.street = 'Requerido';
        }

        if (!address.commune || address.commune.trim().length === 0) {
            formIsValid = false;
            errors.commune = 'Requerido';
        }

        if (!address.number || address.number.trim().length === 0) {
            formIsValid = false;
            errors.number = 'Requerido';
        }

        if (!address.region || address.region.trim().length === 0) {
            formIsValid = false;
            errors.region = 'Requerido';
        }
        this.setState({
            errors,
        });
        return formIsValid;
    }

    async handleCreate() {
        if (this.validForm()) {
            this.toggleLoading(true);
            const { data } = this.state;
            data.role = 2;
            await addUser(data).then((response) => {
                if (response && response.status === 201) {
                    const { callback } = this.props;
                    callback();
                }
            }).catch((error) => {
                this.handleError(error);
            });

            this.toggleLoading(false);
        }
    }

    async handleUpdate() {
        if (this.validForm()) {
            this.toggleLoading(true);
            const { data } = this.state;

            await updateUser(data, data.id).then((response) => {
                if (response && response.status === 200) {
                    const { callback } = this.props;
                    callback();
                }
            }).catch((error) => {
                this.handleError(error);
            });
            this.toggleLoading(false);
        }
    }

    toggleLoading(value) {
        this.setState({
            loading: value,
        });
    }

    render() {
        const { readOnly } = this.props;
        const {
            createMode, errors, data, loading,
        } = this.state;
        const {
            name, rut, phone, email, address, username, attached_forms, password,
        } = data;

        const {
            street, number, commune, region,
        } = address;


        const rest = {
            onChange: this.onChange,
            errors,
            required: true,
        };

        const restAddress = {
            onChange: this.onChangeAddress,
            errors,
            required: true,
        };
        return (
            <>
                <Row>
                    <Col md={12}><Input name="name" label="Nombre" value={name} readOnly={readOnly} icon="fas fa-user" {...rest} /></Col>
                    <Col md={6}><Input name="username" label="Username" value={username} readOnly={readOnly} icon="fas fa-user" {...rest} /></Col>
                    <Col md={6}><Input name="rut" mask="rut" label="Rut" placeholder="Ej: 11111111-1" value={rut} readOnly={readOnly} icon="far fa-address-card" onBlur={this.onBlurRut} onFocus={this.onFocusRut} {...rest} /></Col>
                    <Col md={6}><Input name="phone" mask="phone" label="Teléfono de contacto" placeholder="Ej: 569 9914-8417" value={phone} readOnly={readOnly} icon="fas fa-phone" onBlur={this.onBlurPhone} onFocus={this.onFocusPhone} {...rest} /></Col>
                    <Col md={6}><Input name="street" label="Calle" value={street} readOnly={readOnly} icon="fas fa-map-marked-alt" {...restAddress} /></Col>
                    <Col md={6}><Input name="number" label="Numero" value={number} readOnly={readOnly} icon="fas fa-map-marked-alt" {...restAddress} /></Col>
                    <Col md={6}><Input name="commune" label="Comuna" value={commune} readOnly={readOnly} icon="fas fa-map-marked-alt" {...restAddress} /></Col>
                    <Col md={6}><Input name="region" label="Region" value={region} readOnly={readOnly} icon="fas fa-map-marked-alt" {...restAddress} /></Col>
                    {!readOnly && (
                        <Col md={6}><Input name="password" label="Password" placeholder="******" value={password} readOnly={readOnly} type="password" icon="fas fa-key" {...rest} /></Col>
                    )}
                    <Col md={12}><Input name="email" label="Email" placeholder="correo1@ejemplo.com" value={email} readOnly={readOnly} icon="far fa-envelope" {...rest} /></Col>
                </Row>
                {readOnly && (
                    <Row>
                        <Col md={12}>
                            <b>Formularios</b>
                        </Col>
                        {attached_forms && attached_forms.map((item) => (
                            <Col md={12}>
                                <i style={{ paddingRight: '5px', color: '#868686' }} className="fas fa-address-book" />
                                {' '}
                                {item.name}
                            </Col>
                        ))}
                    </Row>
                )}
                {!readOnly && (
                    <div className="form-footer">
                        {loading && <div className="spinner"><Spinner /></div>}
                        <Button text={createMode ? 'Crear' : 'Actualizar'} onClick={createMode ? this.handleCreate : this.handleUpdate} />
                    </div>
                )}
            </>
        );
    }
}

CustomerForm.propTypes = {
    callback: PropTypes.func,
    data: PropTypes.object,
    readOnly: PropTypes.bool,
};

CustomerForm.defaultProps = {
    callback: null,
    data: null,
    readOnly: false,
};

CustomerForm.contextType = StateContext;

export default CustomerForm;
