/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import ReactTable from 'react-table';
// import matchSorter from 'match-sorter';
import TopBar from '../../Components/TopBar/TopBar';
import { StateContext } from '../../State';
import DownloadCSVButton from '../../Components/DownloadCSVButton/DownloadCSVButton';
import ClientForm from './InstallationForm';
import InstallationFormCreate from './InstallationFormCreate';
import InstallationFormUpdate from './InstallationFormUpdate';
import ModalView from '../../Layout/ModalView/ModalView';
import { ConfirmDialog } from '../../Components/Dialog/Dialog';
import { tableConfig } from '../../config';
import {
    formatRut, checkString, clearString,
} from '../../Service/Utils';
import { deleteInstallation, getInstallations } from '../../Service/Api';


class Installation extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            showConfirm: false,
            showWarning: false,
            data: [],
            filteredData: [],
            loading: false,
            deleteId: null,
        };
        this.loadData = this.loadData.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.findData = this.findData.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    findData = (id) => {
        const { data } = this.state;
        return data.find((item) => item.id === id);
    }

    formattedData = () => {
        const { filteredData } = this.state;
        const result = [];
        filteredData.forEach((item) => {
            result.push({
                ID: item.id,
                Item: item.item,
                Estado: item.status === 'completed' ? 'Concluido' : item.status === 'canceled' ? 'Cancelado' : 'Pendiente',
                'Fecha Instalacion': item.installation_date,
                Municipalidad: item.customer ? item.customer.name : '',
                'Correo Municipalidad': item.customer ? item.customer.email : '',
                'Nombre Cliente': item.client ? `${item.client.name} ${item.client.last_name}` : '',
                'Rut Cliente': item.client ? item.client.rut : '',
                'Correo Cliente': item.client ? item.client.email : '',
                'Nombre Tecnico': item.technical ? `${item.technical.name} ${item.technical.last_name}` : '',
                'Correo Tecnico': item.technical ? item.technical.email : '',
            });
        });
        return result;
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        }
    }

    async loadData() {
        this.setState({
            loading: true,
        });

        await getInstallations()
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        data: response.data,
                        filteredData: response.data,
                        loading: false,
                    });
                }
            }).catch((error) => {
                this.handleError(error);
            });
    }

    async removeClient() {
        const { deleteId } = this.state;
        await deleteInstallation(deleteId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    handleRemove(id) {
        this.setState({
            showConfirm: true,
            deleteId: id,
        });
    }

    filterFunction = (rows, value, keys = 'name') => {
        const filtered = rows.filter((item) => (checkString(clearString(value), clearString(item[keys])) !== -1));
        return filtered;
    };

    convertName(value) {
        switch (value) {
            case 'pending':
                return {
                    status: 'Pendiente',
                    color: 'orange',
                };
            case 'completed':
                return {
                    status: 'Concluido',
                    color: 'green',
                };
            default:
                return {
                    status: 'Cancelado',
                    color: 'red',
                };
        }
    }

    getFilteredData = (filtered) => {
        const { data } = this.state;
        const filteredData = [];
        filtered.forEach((item) => {
            const f = data.find((element) => element.id === item.id);
            if (f) {
                filteredData.push(f);
            }
        });
        this.setState({ filteredData });
    }

    render() {
        const {
            showConfirm, showWarning, data, loading,
        } = this.state;
        const [{ role }] = this.context;
        const columns = [
            {
                Header: '#',
                accessor: 'id',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>{row.original.id}</div>
                ),
                filterMethod: (filter, rows) => this.filterFunction(rows, filter.value, 'id'),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Cliente',
                accessor: 'client.name',
                Cell: (row) => (
                    <ModalView title={row.original.client.name}>
                        <ClientForm data={this.findData(row.original.id)} readOnly />
                    </ModalView>
                ),
                filterMethod: (filter, rows) => this.filterFunction(rows, filter.value, 'client.name'),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Rut',
                accessor: 'client.rut',
                maxWidth: 100,
                Cell: (row) => (
                    <span>{formatRut(row.original.client.rut)}</span>
                ),
                filterMethod: (filter, rows) => this.filterFunction(rows, filter.value, 'client.rut'),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Fecha Instalación',
                accessor: 'installation_date',
                maxWidth: 150,
                Cell: (row) => (
                    <span>{row.original.installation_date}</span>
                ),
                filterMethod: (filter, rows) => this.filterFunction(rows, filter.value, 'installation_date'),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Municipalidad',
                accessor: 'customer.name',
                Cell: (row) => <span>{row.original.customer.name}</span>,
                filterMethod: (filter, rows) => this.filterFunction(rows, filter.value, 'customer.name'),
                filterAll: true,
                filterable: true,
                show: true,
            },
            {
                Header: 'Tecnico',
                accessor: 'technical.name',
                Cell: (row) => <span>{row.original.technical ? row.original.technical.name : ''}</span>,
                filterMethod: (filter, rows) => this.filterFunction(rows, filter.value, 'technical.name'),
                filterAll: true,
                filterable: true,
                show: true,
            },
            {
                Header: 'Status',
                id: 'status',
                accessor: (row) => this.convertName(row.status).status,
                Cell: (row) => <span style={{ textTransform: 'capitalize', fontWeight: 'bold', color: this.convertName(row.original.status).color }}>{this.convertName(row.original.status).status}</span>,
                filterMethod: (filter, rows) => this.filterFunction(rows, filter.value, 'status'),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Info',
                accessor: 'technical',
                Cell: (row) => (
                    <div className="form-actions">
                        {row.original.technical === null && (
                            <button type="button" onClick={() => this.setState({ showWarning: true })}>
                                <i className="fas fa-exclamation-triangle" />
                            </button>
                        )}
                    </div>
                ),
                filterAll: false,
                filterable: false,
                maxWidth: 60,
            },
            {
                Header: 'Acciones',
                id: 'actions',
                filterable: false,
                sortable: false,
                maxWidth: 100,
                show: role.id === 1,
                Cell: (row) => (
                    <div className="form-actions">

                        <ModalView title="Editar cliente" type="edit" callback={this.loadData}>
                            <InstallationFormUpdate data={this.findData(row.original.id)} />
                        </ModalView>
                        <button onClick={() => { this.handleRemove(row.original.id); }} type="button">
                            <i className="fas fa-trash" />
                        </button>

                    </div>
                ),
            },
        ];


        return (
            <>
                <TopBar>
                    <DownloadCSVButton data={this.formattedData()} filename="instalaciones.csv" />
                    {(role.id === 1 || role.id === 3) && (
                        <ModalView title="Crear cliente" type="add" callback={this.loadData}>
                            <InstallationFormCreate />
                        </ModalView>
                    )}
                </TopBar>

                <ReactTable
                    data={data}
                    columns={columns}
                    {...tableConfig}
                    loading={loading}
                    ref={(r) => {
                        this.reactTableRef = r;
                    }}
                    onFilteredChange={() => {
                        this.getFilteredData(this.reactTableRef.getResolvedState().sortedData);
                    }}
                />

                <ConfirmDialog
                    show={showConfirm}
                    title="Eliminar instalación"
                    onConfirm={() => {
                        this.removeClient();
                        this.setState({ showConfirm: false });
                    }}
                    onCancel={() => {
                        this.setState({ showConfirm: false });
                    }}
                />

                <ConfirmDialog
                    show={showWarning}
                    title=""
                    message="Ningún técnico está asociado con esta instalación"
                    confirmTxt="Confirmar"
                    cancelTxt="Cancelar"
                    btnStyle="default"
                    onConfirm={() => {
                        this.setState({ showWarning: false });
                    }}
                    onCancel={() => {
                        this.setState({ showWarning: false });
                    }}
                />
            </>
        );
    }
}

Installation.contextType = StateContext;

export default Installation;
