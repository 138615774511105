export default [
    {
        id: 1,
        title: 'Formularios',
        path: '/admin/formularios',
        icon: 'far fa-clipboard',

    },
    {
        id: 2,
        title: 'Municipalidades',
        path: '/admin/municipalidad',
        icon: 'far fa-address-book',

    },
    {
        id: 3,
        title: 'Etapas',
        path: '/admin/etapas',
        icon: 'fas fa-hourglass-start',
    },
    {
        id: 4,
        title: 'Tecnicos',
        path: '/admin/tecnicos',
        icon: 'fas fa-users-cog',

    },
    {
        id: 5,
        title: 'Registros',
        path: '/registros',
        icon: 'fas fa-clipboard',
    },
    {
        id: 6,
        title: 'Borrador',
        path: '/admin/borrador',
        icon: 'fab fa-firstdraft',

    },
    // {
    //     id: 5,
    //     title: 'Clientes',
    //     path: '/admin/clientes',
    //     icon: 'far fa-address-book',

    // },
    // {
    //     id: 7,
    //     title: 'Instalación',
    //     path: '/admin/instalacion',
    //     icon: 'far fa-calendar-check',
    // },
];
